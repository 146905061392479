import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
  Spin,
  notification,
  
} from "antd";
import {
  DribbbleOutlined,
  TwitterOutlined,
  InstagramOutlined,
  GithubOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import logo from '../assets/images/PNG/CGS/CGS-Green.png';
import signinbg from '../assets/images/JPEG/CGS-coloured.jpg';

const { Header, Footer, Content } = Layout;
const { Title } = Typography;

function SignIn() {
  const history = useHistory();
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await fetch(
        'https://handyman-j1j1.onrender.com/api/v1/auth/login',
        // 'https://handyman-j1j1.onrender.com/api/v1/auth/login',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        }
      );

      if (response.ok) {
        // Login successful, redirect to the desired page
        history.push('/orders');
      } else {
        // Handle login error
        notification.error({
          message: 'Login Failed',
          description: response.statusText,
        });
      }
    } catch (error) {
      console.error('Login failed:', error);
      notification.error({
        message: 'Login Failed',
        description: 'An error occurred while processing your login request.',
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Login form validation failed:', errorInfo);
  };

  const onChangeRememberMe = (checked) => {
    setRememberMe(checked);
  };

  const menuSocialIcons = [
    { icon: <DribbbleOutlined />, link: '#' },
    { icon: <TwitterOutlined />, link: '#' },
    { icon: <InstagramOutlined />, link: '#' },
    {
      icon: (
        <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"
          ></path>
        </svg>
      ),
      link: '#',
    },
    { icon: <GithubOutlined />, link: '#' },
  ];

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Header>
          <div className="header-col header-brand">
            <img src={logo} alt="Logo" style={{ width: "150px", height: "auto" }} />
          </div>
        </Header>
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 2 }} md={{ span: 12 }}>
              <Title className="mb-15">Sign In</Title>
              <Title className="font-regular text-muted" level={5}>
                Enter your email and password to sign in
              </Title>
              <Form onFinish={onFinish} onFinishFailed={onFinishFailed} layout="vertical" className="row-col">
                <Form.Item
                  className="username"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item name="remember" className="aligin-center" valuePropName="checked">
                  <Switch defaultChecked={rememberMe} onChange={onChangeRememberMe} />
                  Remember me
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%', backgroundColor: "green" }}
                    disabled={loading}
                  >
                    {loading ? <Spin indicator={<LoadingOutlined />} /> : 'SIGN IN'}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col className="sign-img" style={{ padding: 12 }} xs={{ span: 24 }} lg={{ span: 12 }} md={{ span: 12 }}>
              <img src={signinbg} alt="" />
            </Col>
          </Row>
        </Content>

        <Footer>
          <Menu mode="horizontal">
            <Menu.Item>Company</Menu.Item>
            <Menu.Item>About Us</Menu.Item>
            <Menu.Item>Teams</Menu.Item>
            <Menu.Item>Products</Menu.Item>
            <Menu.Item>Blogs</Menu.Item>
            <Menu.Item>Pricing</Menu.Item>
          </Menu>
          <Menu mode="horizontal" className="menu-nav-social">
            {menuSocialIcons.map((item, index) => (
              <Menu.Item key={index}>
                <Link to={item.link}>{item.icon}</Link>
              </Menu.Item>
            ))}
          </Menu>
          <p className="copyright">
            {" "}
            Copyright © 2023 CGS <a href="#pablo"></a>.{" "}
          </p>
        </Footer>
      </Layout>
    </>
  );
}

export default SignIn;
