/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";
import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
// import logo from "../../assets/images/logo.png";
import logo from '../../assets/images/PNG/CGS/CGS-Green.png';


function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");



  const tables = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const logout = [
<svg
  version="1.1"
  id="Capa_1"
  xmlns="http://www.w3.org/2000/svg"
  // xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 512 512"
  // xml:space="preserve"
  width="512px"
  height="512px"
  fill="#000000"
>
  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
  <g
    id="SVGRepo_tracerCarrier"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></g>
  <g id="SVGRepo_iconCarrier">
    <path
      d="M224 256l-96-96v64h-96v64h96v64l96-96zm240-240h-112v32h112c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48H112c-26.51 0-48-21.49-48-48V96c0-26.51 21.49-48 48-48h112V16H112c-44.183 0-80 35.817-80 80v352c0 44.183 35.817 80 80 80h352c44.183 0 80-35.817 80-80V96c0-44.183-35.817-80-80-80zm-32 376c0 8.836-7.164 16-16 16H112c-8.836 0-16-7.164-16-16V96c0-8.836 7.164-16 16-16h304c8.836 0 16 7.164 16 16v336zm-208-56h144v32H224v-32z"
      fill="#000000"
    ></path>
  </g>
</svg>
    ];



  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        {/* <span>CGS</span> */}
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          {/* <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              {dashboard}
            </span>
            <span className="label">Dashboard</span>
          </NavLink> */}
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/orders">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              {tables}
            </span>
            <span className="label">Orders</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          {/* <NavLink to="/billing">
            <span
              className="icon"
              style={{
                background: page === "billing" ? color : "",
              }}
            >
              {billing}
            </span>
            <span className="label">Billing</span>
          </NavLink> */}
        </Menu.Item>
        {/* <Menu.Item key="4">
          <NavLink to="/rtl">
            <span
              className="icon"
              style={{
                background: page === "rtl" ? color : "",
              }}
            >
              {rtl}
            </span>
            <span className="label">RTL</span>
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item className="menu-item-header" key="5">
          Account Pages
        </Menu.Item> */}
        {/* <Menu.Item key="6">
          <NavLink to="/profile">
            <span
              className="icon"
              style={{
                background: page === "profile" ? color : "",
              }}
            >
              {profile}
            </span>
            <span className="label">Profile</span>
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item key="7">
          <NavLink to="/sign-in">
            <span className="icon">{signin}</span>
            <span className="label">Sign In</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="8">
          <NavLink to="/sign-up">
            <span className="icon">{signup}</span>
            <span className="label">Create New Admin</span>
          </NavLink>
        </Menu.Item> */}
        <Menu.Item key="4">
          <NavLink to="/">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              {logout}
            </span>
            <span className="label">Logout</span>
          </NavLink>
        </Menu.Item>
      </Menu>

    </>
  );
}

export default Sidenav;
