import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
  Modal,
  Descriptions,
  Select,
  Input,
} from "antd";
import moment from "moment";



import face2 from "../assets/images/PNG/Icons/CG-blue.png";
const { Title } = Typography;

const formProps = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};


function Tables() {
  const onChange = (e) => console.log(`radio checked:${e.target.value}`);
  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editableData, setEditableData] = useState({});



  const openModal = (record) => {
    setSelectedRowData(record);
    setEditableData({ ...record });

    // If it's a new service, set the name in the editableData state
    if (record?.services && record.services.length > 0 && record.services[0].isNewService) {
      setEditableData((prevData) => ({
        ...prevData,
        name: record.services[0].name,
      }));
    }

    setModalVisible(true);
  };

  console.log(`Data:`, data.services);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Location",
      key: "location",
      dataIndex: "location",
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
    },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",

      render: (text, record) => (
        <Button type="primary" onClick={() => openModal(record)}>
          View Details
        </Button>
      ),
    },
  ];

  console.log(data)

  useEffect(() => {
    fetchData();
  }, []);

  const updateOrder = async () => {
    try {
      const response = await fetch(
        `https://handyman-j1j1.onrender.com/api/v1/service/order/update/${selectedRowData.key}`,
        // `http://localhost:3001/api/v1/service/order/update/${selectedRowData.key}`,

        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(editableData),
        }
      );

      if (response.ok) {
        // Order updated successfully, handle success case
        console.log('Order updated successfully');
        closeModal();
      } else {
        // Handle error case
        console.error('Failed to update order');
      }
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  // const deleteOrder = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://jade-gentle-rhinoceros.cyclic.app/api/v1/service/order/${selectedRowData.key}`,
  //       {
  //         method: 'DELETE',
  //       }
  //     );

  //     if (response.ok) {
  //       // Order deleted successfully, handle success case
  //       console.log('Order deleted successfully');
  //       closeModal();
  //     } else {
  //       // Handle error case
  //       console.error('Failed to delete order');
  //     }
  //   } catch (error) {
  //     console.error('Error deleting order:', error);
  //   }
  // };

  const deleteOrder = async (id) => {
    try {
      const response = await fetch(
        `https://jade-gentle-rhinoceros.cyclic.app/api/v1/service/order/${id}`,
        {
          method: 'DELETE',
        }
      );

      if (response.ok) {
        // Order deleted successfully, update the table
        setData((prevData) => prevData.filter((item) => item.key !== id));
        closeModal();
      } else {
        // Handle error case
        console.error('Failed to delete order');
      }
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };


  const fetchData = async () => {
    try {
      // Call the API to fetch data
      // const response = await fetch('https://jade-gentle-rhinoceros.cyclic.app/api/v1/service/orders/all');
      const response = await fetch('https://handyman-j1j1.onrender.com/api/v1/service/orders/all');
      const result = await response.json();
      const services = result.data;
      console.log(`Services:`, services);

      // Transform the data to match the table structure
      const transformedData = services.map((service, index) => ({
        // const serviceData = service.services.map((innerService) => ({
        key: service._id,
        servicesName: (
          <>
            <div className="ant-employed">
              <Title level={5}>{service.services[0].name}</Title>
            </div>
          </>
        ),
        servicesDesc: (
          <>
            <div className="ant-employed">
              <Title level={5}>{service.services[0].description}</Title>
            </div>
          </>
        ),
        name: (
          <>
            <div className="ant-employed">
              <Title level={5}>{service.name}</Title>
            </div>
          </>
        ),
        phone: (
          <>
            <Avatar.Group>
              {/* <Avatar
                className="shape-avatar"
                shape="square"
                size={40}
                src={face2}
              /> */}
              <div className="avatar-info">
                <Title level={5}>{service.phone}</Title>
                {/* <p>{service.description}</p> */}
              </div>
            </Avatar.Group>
          </>
        ),
        budget: (
          <>
            <div className="author-info">
              <Title level={5}>{`₦${service.services[0].budget.min} - ₦${service.services[0].budget.max}`}</Title>
              {/* <p>Category</p> */}
            </div>
          </>
        ),
        status: (
          <>
            <Button type="primary" className="tag-primary">
              {service.services[0].status}
            </Button>
          </>
        ),
        location: (
          <>
            <div className="ant-employed">
            <Title level={5}>{service.services[0].location.split(' ').slice(0, 2).join(' ')}</Title>
            </div>
          </>
        ),

        date: (
          <>
            <div className="ant-employed">
              <span>
                {new Date(service.services[0].date).toLocaleDateString()}
              </span>
            </div>
          </>
        ),
        action: (
          <>
            <div className="ant-employed">


              <Button type="primary" >
                Update Order
              </Button>

              {/* <a href="#pablo" className="ant-employed">Edit</a> */}
            </div>
          </>
        ),
      }));

      setData(transformedData);
      // console.log('transformedData: ', transformedData.services[0]);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };




  const closeModal = () => {
    setModalVisible(false);
    setSelectedRowData(null);
  };


  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Orders Table"
              extra={
                <>
                  <Radio.Group onChange={onChange} defaultValue="a">
                    <Radio.Button value="a">All</Radio.Button>
                    {/* <Radio.Button value="b">ONLINE</Radio.Button> */}
                  </Radio.Group>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={true}
                  className="ant-border-space"
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        setModalVisible(true);
                        setSelectedRowData(record);
                      },
                    };
                  }}
                />
              </div>
            </Card>
            {/* <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Projects Table"
              extra={
                <>
                  <Radio.Group onChange={onChange} defaultValue="all">
                    <Radio.Button value="all">All</Radio.Button>
                    <Radio.Button value="online">ONLINE</Radio.Button>
                    <Radio.Button value="store">PHYSICAL</Radio.Button>
                  </Radio.Group>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={project}
                  dataSource={dataproject}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
              <div className="uploadfile pb-15 shadow-none">
                <Upload {...formProps}>
                  <Button
                    type="dashed"
                    className="ant-full-box"
                    icon={<ToTopOutlined />}
                  >
                    Click to Upload
                  </Button>
                </Upload>
              </div>
            </Card> */}
          </Col>
        </Row>
      </div>
      <Modal
        visible={modalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <Button key="delete" type="danger" onClick={deleteOrder}>
            Delete
          </Button>,
          <Button key="update" type="primary" onClick={updateOrder}>
            Update
          </Button>,
        ]}
      >
        {selectedRowData && (
          <div>
            <h2>Row Data</h2>
            <Descriptions title="Service Details" layout="vertical">
              <Descriptions.Item label="Phone Number">
                {selectedRowData.phone}
              </Descriptions.Item>
              <Descriptions.Item label="Customer Name">
                {selectedRowData.name}
              </Descriptions.Item>
              <Descriptions.Item label="Service Name">
                {selectedRowData.servicesName}
              </Descriptions.Item>

              <Descriptions.Item label="Service Description" style={{width: "100%"}}>
                {selectedRowData.servicesDesc}
              </Descriptions.Item>
              {/* <h1>Service Ordered </h1>  */}
              {/* <hr /> */}
              <Descriptions.Item label="Status">
              {selectedRowData.status}
                {/* <Select
                  value={selectedRowData.status}  // Use editableData here
                  onChange={(value) =>
                    setEditableData({ ...editableData, status: value })
                  }
                >
                  <Select.Option value="Pending">Pending</Select.Option>
                  <Select.Option value="Accepted">Accepted</Select.Option>
                  <Select.Option value="Completed">Completed</Select.Option>
                  <Select.Option value="Cancelled">Cancelled</Select.Option>
                </Select> */}
              </Descriptions.Item>
              <Descriptions.Item label="Location">
                {selectedRowData.location}
              </Descriptions.Item>
              <Descriptions.Item label="Date">
                {/* {new Date(service.services[0].date).toLocaleDateString()} */}
                {moment(editableData.date).format("YYYY-MM-DD")}  {/* Format the date */}
              </Descriptions.Item>
              <Descriptions.Item label="Budget">
                {selectedRowData.budget}
              </Descriptions.Item>
            </Descriptions>

          </div>
        )}
      </Modal>
    </>
  );
}

export default Tables;
